import React, { useEffect,useState } from 'react'
import {Divider,  Table,Grid, Button, Select, MenuItem,Container ,Stack,Box,InputLabel, Typography, TableHead, TableRow, TableCell,useTheme, TableBody} from '@mui/material';
// import { useMsal } from "@azure/msal-react";
import Cookies from 'js-cookie';

export default function Reports({loading}) {
  const [chosenLocation, setChosenLocation] = useState(0);
  const [chosenOrder, setChosenOrder] = useState(0);
  const [orderType, setOrderType] = useState('choose');
  const [locations, setLocations] = useState([]);
  const [orders, setOrders] = useState([]);
  const [results, setResults] = useState(null);
  const theme = useTheme();
  const accounts=[{username:'1',name:'1'}]
  // const { accounts } = useMsal();
  const username = accounts[0] && accounts[0].username;
  const name = accounts[0] && accounts[0].name;
  const location_id = Cookies.get('location_id');



  const handleSubmit = (output) => {
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/reports`,{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          location_id: chosenLocation,
          order_type: orderType,
          order_date: chosenOrder,
          output: output
        })
      }).then(res => res.json()).then(data => {
        console.log("data",data)
        setResults(data)
      }).catch(err => console.log(err))
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/orders`).then(res => res.json()).then(data => {
      setOrders(data)
    })
  },[])
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/locations`).then(res => res.json()).then(data => {
    setLocations(data)
    })

  },[])
  useEffect(() => {
    if(location_id==100 || location_id==101){

    }else{
      setChosenLocation(location_id)
    }
  },[location_id])

  return (
    <Container maxWidth="lg">
      <Grid container>
        <Grid item={true} xs={12} sm={3} md={3}lg={2} sx={{borderRight:'1px solid #EDEDED'}}>
          <Stack direction="column" alignItems="flex-start" spacing={2} sx={{borderRadius:2,p:2,background:'#ffffff'}}>
            <Typography variant="h6">Filter Orders</Typography>
            <Box sx={{width:'100%'}}>
              <InputLabel>Order Type</InputLabel>
              <Select fullWidth sx={{height:40}}
                value={orderType}

                onChange={(e) => setOrderType(e.target.value)}
              >
                <MenuItem value="choose">Choose Type</MenuItem>
                  <MenuItem value="open">Open Orders</MenuItem>
                  <MenuItem value="closed">Closed Orders</MenuItem>
              </Select>
            </Box>
            {orderType=='closed' &&
              <Box sx={{width:'100%'}}>
                <InputLabel id="demo-simple-select-label">Date</InputLabel>
                <Select fullWidth sx={{height:40}}
                  value={chosenOrder}
                  onChange={(e) => setChosenOrder(e.target.value)}
                >
                  <MenuItem value={0}>Choose Order</MenuItem>
                  {orders && orders.map((order,index) => (
                    <MenuItem key={index} value={`${order.month}-${order.year}`}>{order.month}-{order.year}</MenuItem>
                  ))}
                </Select>
              </Box>
            }

            <Box sx={{width:'100%'}}>
              <InputLabel id="demo-simple-select-label">Location</InputLabel>
 {(location_id==100 || location_id==101) &&
              <Select fullWidth sx={{height:40}}
              value={chosenLocation}
              onChange={(e) => setChosenLocation(e.target.value)}
              >
                <MenuItem value={0}>All Locations</MenuItem>

                {locations && locations.map((location,index) => (
                    <MenuItem key={index} value={location.id}>{location.name}</MenuItem>
                    ))}




              </Select>
               }
            </Box>
            <Box sx={{width:'100%'}}>
              <Button fullWidth onClick={()=>handleSubmit('screen')} variant="contained">
                Show Results
              </Button>
            </Box>
            {(location_id==101 || location_id==100) &&
            <Box sx={{width:'100%'}}>
              <Button sx={{'&:hover':{
                backgroundColor:theme.palette.secondary.dark
              },backgroundColor:theme.palette.secondary.main}} fullWidth onClick={()=>handleSubmit('csv')} variant="contained">
                Generate & Email CSV
              </Button>
            </Box>
            }
            {location_id==101 &&
            <Box sx={{width:'100%'}}>
              <Button sx={{'&:hover':{
                backgroundColor:'#ca6021'
              },backgroundColor:'#ca6021'}} fullWidth onClick={()=>handleSubmit('csvclose')} variant="contained">
                Close orders & Email CSV
              </Button>
            </Box>
            }
          </Stack>
        </Grid>
        <Grid item={true} xs={12} sm={9} md={9}lg={10}>
            <>
            {results==null ?
              <Box sx={{p:2}}>
                <Typography>Choose your filters to get reports</Typography>
              </Box>
            :<>
            {results.length==0 ?
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{p:2}}>
              <Typography>Choose your filters to get reports</Typography>
              </Stack>:
              <Stack direction="column" alignItems="center" justifyContent="space-between" sx={{p:2}}>
               <Stack direction="row" sx={{width:'100%'}} spacing={3}>
                {chosenLocation==0 ? <Typography>Location: All</Typography> : <Typography>Location: {chosenLocation}</Typography>}
                {orderType == 'open' ? <Typography>Order Type: Open</Typography> : <Typography>Order Type: Closed</Typography>}
                </Stack>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{fontWeight:'bold'}}>Product</TableCell>
                      <TableCell sx={{fontWeight:'bold'}}>Total Qty</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {results && results.map((result,index) => (
                    <TableRow key={index}>
                      <TableCell>{result.product_name}</TableCell>
                      <TableCell>{result.total_qty}</TableCell>
                    </TableRow>
                  ))}
                  </TableBody>
                </Table>
              </Stack>
            }
            </>
            }
            </>
          </Grid>
      </Grid>
    </Container>
  )
}
