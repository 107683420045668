import React,{useState,useEffect} from 'react'
// import { useIsAuthenticated } from "@azure/msal-react";
// import { SignInButton } from "../../components/auth/SignInButton";
// import { SignOutButton } from "../../components/auth/SignOutButton";
// import { useMsal } from "@azure/msal-react";
import {Box,Stack,Button, Typography, Link} from '@mui/material';
import { SignOutButton, SignInButton, SignedIn, SignedOut,useUser } from "@clerk/clerk-react"
import { dark, neobrutalism, shadesOfPurple } from '@clerk/themes';
import Cookies from 'js-cookie';

export default function Layout({children}) {
  const { isSignedIn, user, isLoaded } = useUser();
  const [username, setUsername] = useState(null);
  const [location_id, setLocation_id] = useState(null);
  const [name,setName]=useState(null);

  useEffect(() => {

     if(typeof(user) !==undefined && user && user.emailAddresses &&  user.emailAddresses[0].emailAddress){
       setUsername(user.emailAddresses[0].emailAddress)
       console.log("user",user)
     }
    if(typeof(user) !==undefined && user && user.firstName){
      setName(user.firstName)
    }
  }, [user]);

  useEffect(() => {

    if (username) {
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/user`, {
            method: "POST",
            body: JSON.stringify({ username }),
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then((response) => response.json())
        .then((data) => {
          if(data.length>0){
              Cookies.set('location_id', data[0].location_id);
              setLocation_id(data[0].location_id)
          }
        })
    }
  }, [username]);


  // const isAuthenticated = useIsAuthenticated();
  // const { accounts } = useMsal();
  // const accounts=[{username:'1',name:'1'}]
  // const username = accounts[0] && accounts[0].username;
  //  const name = accounts[0] && accounts[0].name;

  return (
    <Box component="main" sx={{backgroundColor:'#ededed'}}>
      <Box component="header" sx={{px:4,background:'#fff',height:username =='' ? '100vh' :'auto'}}>
        <Stack direction="row" spacing={2} sx={{alignItems:"center",justifyContent:"space-between"}}>
          <Stack direction="row" alignItems="center" spacing={2}>
           <Link href="/"><Box component="img" src="/logo-group.png" alt="Westerleigh Group" sx={{width:200}} /></Link>
            <Stack direction="row" alignItems="flex-start" spacing={4}>
              <Box>
                {
                  (
                    location_id==100 ||
                    location_id==101
                  )
                  ?
                  <Stack direction="row" spacing={2}>
                    <Button variant="text" sx={{color:'#000'}} href="/">Order Form</Button>
                    <Button  sx={{color:'#000'}}  variant="text" href="/reports">Reports</Button>
                  </Stack>
                  :
                  <></>
                }
              </Box>
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={0}>
            <SignedOut>
              {/* <p>This content is public. Only signed out users can see the SignInButton above this text.</p> */}
            </SignedOut >

            <SignedIn>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Box>Welcome {name}</Box>
                <SignOutButton  afterSignOutUrl="/" appearance={{baseTheme: dark}}  />
              </Stack>
            </SignedIn>
            {/* { isAuthenticated   && <Typography variant="body1" sx={{fontSize:13}}>Welcome {name} |</Typography>}
              <Box>{ isAuthenticated ? <SignOutButton /> : <SignInButton /> }</Box> */}
          </Stack>
        </Stack>
      </Box>
      <Box sx={{p:4}}>
      {children}
      </Box>
    </Box>
  )
}
