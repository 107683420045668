import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App.jsx";
// import { PublicClientApplication } from "@azure/msal-browser";
// import { MsalProvider } from "@azure/msal-react";
// import { msalConfig } from "./utils/authConfig";
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/theme';

// const msalInstance = new PublicClientApplication(msalConfig);
ReactDOM.render(
    <React.StrictMode>
        {/* <MsalProvider instance={msalInstance}> */}
        <ThemeProvider theme={theme}>
            <App />
            </ThemeProvider>
        {/* </MsalProvider> */}
    </React.StrictMode>,
    document.getElementById("root")
);
