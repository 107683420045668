"use client"
import React, { useEffect } from "react";
// import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
// import ProfileContent from "./components/auth/ProfileContent";
import "./styles/App.css";
import Layout from "./theme/layout/Layout";
import ShopFront from "./components/shop";
import Reports from "./components/reports";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Box } from '@mui/system';
import { Stack } from "@mui/material";
import { light } from '@clerk/themes';
import { SignOutButton, SignInButton, SignedIn, SignedOut, useUser } from "@clerk/clerk-react"

// Import your publishable key
const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY

if (!PUBLISHABLE_KEY) {
    throw new Error("Missing Publishable Key")
}
import { ClerkProvider } from '@clerk/clerk-react'
import SignInForm from "./components/SignInForm";
import DashOut from "./components/DashOut";
import SignUp from "./components/SignUp";

export default function App() {

    // const { accounts } = useMsal();
    // console.log("asd", isSignedIn)
    // const accounts = [{ username: '1', name: '1' }]
    // const username = accounts[0] && accounts[0].username;
    const username = 'undefined'


    return (
        <>
            <ClerkProvider appearance={{
                baseTheme: light
            }} publishableKey={PUBLISHABLE_KEY}>
                <Layout>
                    <SignedIn>
                        {/* <ProfileContent /> */}
                        <BrowserRouter>
                            <Routes>

                                <Route path="/" element={<ShopFront />} />
                                <Route path="/reports" element={<Reports />} />

                            </Routes>
                        </BrowserRouter>
                    </SignedIn>

                    <SignedOut>

                        {/* <SignInForm /> */}
                        <Stack alignItems="center" justifyContent="flex-start" sx={{ height: '100vh', width: '100%', background: '#EDEDED' }}>


                            <BrowserRouter>
                                <Routes>
                                    <Route path="/sign-up" element={<SignUp />} />
                                    <Route path="/" element={<DashOut />} />
                                    {/* <Route path="/sign-in" element={<SignInForm />} /> */}
                                </Routes></BrowserRouter>
                        </Stack>
                    </SignedOut>
                </Layout >
            </ClerkProvider>
        </>
    );
}
